import gql from "graphql-tag";

export default {
  methods: {
    insert_purchase: async function (purchase) {
      const _purchase = { ...purchase };
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation create_purchase($purchase: purchases_insert_input = {}) {
            insert_purchases_one(object: $purchase) {
              id
            }
          }
        `,
        variables: {
          purchase: _purchase,
        },
      });
      return data.insert_purchases_one.id;
    },
    getVideos: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query GetVideos {
            videos(
              order_by: { sequence: asc }
              where: { show: { _eq: true } }
            ) {
              cf_embed
              created_at
              description
              short_description
              title
              id
            }
          }
        `,
      });
      return data.videos;
    },
    getVideo: async function (videoId) {
      const { data } = await this.$apollo.query({
        query: gql`
          query SingleVideo($id: Int!) {
            videos_by_pk(id: $id) {
              cf_embed
              created_at
              description
              short_description
              id
              oto_required
              show
              sequence
              title
            }
          }
        `,
        variables: {
          id: videoId,
        },
      });
      return data.videos_by_pk;
    },
    createVideo: async function (video) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation InsertVideo($object: videos_insert_input!) {
            insert_videos_one(object: $object) {
              id
            }
          }
        `,
        variables: {
          object: video,
        },
      });
      return data.insert_videos_one.id;
    },
    updateVideo: async function (id, video) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateVideo($id: Int!, $object: videos_set_input!) {
            update_videos_by_pk(pk_columns: { id: $id }, _set: $object) {
              id
            }
          }
        `,
        variables: {
          id,
          object: video,
        },
      });
      return data.update_videos_by_pk.id;
    },
    getOldLanguage: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query ReloadLanguage {
            campaigns(
              order_by: { created: desc }
              limit: 5
              where: { status: { _neq: "created" } }
            ) {
              id
              language
              venue_name
              events(order_by: { start_date: desc }, limit: 1) {
                start_date
              }
            }
          }
        `,
      });
      return data.campaigns;
    },
    getContentTemplates: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query AllCampaignTemplates {
            template(
              where: { is_campaign: { _eq: true } }
              order_by: { group: asc, name: asc }
            ) {
              id
              name
              template
              updated_at
              created_at
              group
              campaign_type
            }
          }
        `,
      });
      return data.template;
    },
    passwordChangeAccount: async function (password) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation passwordChangeAccount($password: String!) {
            action_password_reset_account(password: $password) {
              ok
              reason
            }
          }
        `,
        variables: {
          password,
        },
      });
      return data.action_password_reset_account;
    },
    passwordResetOne: async function (email) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation passwordResetOne($email: String!) {
            action_password_reset_1(email: $email) {
              ok
              reason
            }
          }
        `,
        variables: {
          email,
        },
      });
      return data.action_password_reset_1;
    },
    passwordResetTwo: async function (token, password) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation passwordResetTwo($token: String!, $password: String!) {
            action_password_reset_2(token: $token, password: $password) {
              ok
              reason
            }
          }
        `,
        variables: {
          token,
          password,
        },
      });
      return data.action_password_reset_2;
    },
    getGeoJson: async function (lat, long, distance) {
      const { data } = await this.$apollo.query({
        query: gql`
          query GeoJson($distance: Int!, $lat: Float!, $long: Float!) {
            action_geojson(distance: $distance, lat: $lat, long: $long) {
              ok
              result
            }
          }
        `,
        variables: {
          lat,
          long,
          distance,
        },
      });
      if (data.action_geojson.ok) {
        return { ok: true, result: JSON.parse(data.action_geojson.result) };
      }
      return data.action_geojson;
    },
    createSeminar: async function (seminar) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateSeminar($seminar: campaigns_insert_input!) {
            insert_campaigns_one(object: $seminar) {
              id
            }
          }
        `,
        variables: {
          seminar,
        },
      });
      return data.insert_campaigns_one.id;
    },
    insertTerritories: async function (territories) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation InsertTerritories($objects: [territories_insert_input!]!) {
            insert_territories(objects: $objects) {
              affected_rows
            }
          }
        `,
        variables: {
          objects: territories,
        },
      });
      return data.insert_territories.affected_rows;
    },
    processPayment: async function (
      seminar_id,
      token,
      description,
      amount,
      requested_count
    ) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ProcessPayment(
            $seminar_id: Int!
            $token: String!
            $description: String!
            $amount: Int!
            $requested_count: Int!
          ) {
            action_process_payment(
              seminar_id: $seminar_id
              token: $token
              description: $description
              amount: $amount
              requested_count: $requested_count
            ) {
              ok
              reason
              receipt
              charge_id
            }
          }
        `,
        variables: {
          seminar_id,
          token,
          description,
          amount,
          requested_count,
        },
      });
      return data.action_process_payment;
    },
    processGenericPayment: async function (
      purchase_id,
      token,
      quantity,
      purchase_type
    ) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation ProcessGenericPayment(
            $purchase_id: Int!
            $token: String!
            $quantity: Int!
            $purchase_type: String!
          ) {
            action_process_generic_payment(
              purchase_id: $purchase_id
              token: $token
              quantity: $quantity
              purchase_type: $purchase_type
            ) {
              ok
              reason
              receipt
              charge_id
            }
          }
        `,
        variables: {
          purchase_id,
          token,
          quantity,
          purchase_type,
        },
      });
      return data.action_process_generic_payment;
    },
    updateSeminar: async function (seminar_id, seminar) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateSeminar(
            $seminar_id: Int!
            $seminar: campaigns_set_input!
          ) {
            update_campaigns_by_pk(
              pk_columns: { id: $seminar_id }
              _set: $seminar
            ) {
              id
            }
          }
        `,
        variables: {
          seminar_id,
          seminar,
        },
      });
      return data.update_campaigns_by_pk.id;
    },
    getEventsbyUser: async function (user_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query AllEvents($user_id: Int!) {
            campaigns(
              where: { user_id: { _eq: $user_id }, status: { _neq: "created" } }
            ) {
              id
              venue_name
              events {
                id
                start_date
                start_time
              }
            }
          }
        `,
        variables: {
          user_id,
        },
      });

      let events = [];
      for (const campaign of data.campaigns) {
        for (const event of campaign.events) {
          events.push({
            event_id: event.id,
            campaign_id: campaign.id,
            venue_name: campaign.venue_name,
            start_date: event.start_date,
            start_time: event.start_time,
          });
        }
      }
      return events;
    },
    createEvent: async function (event) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateEvent($event: events_insert_input!) {
            insert_events_one(object: $event) {
              id
            }
          }
        `,
        variables: {
          event,
        },
      });
      return data.insert_events_one.id;
    },
    clearEvents: async function (campaign_id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteEvents($campaign_id: Int!) {
            delete_events(where: { campaign_id: { _eq: $campaign_id } }) {
              affected_rows
            }
          }
        `,
        variables: {
          campaign_id,
        },
      });
      return data.delete_events.affected_rows;
    },
    campaignList: async function (user_id) {
      let result = await this.$apollo.query({
        query: gql`
          query CampaignList($user_id: Int!) {
            campaigns(
              where: { user_id: { _eq: $user_id }, status: { _neq: "created" } }
            ) {
              id
              city
              state
              requested_count
              venue_name
              events {
                id
                start_date
                start_time
                capacity
                households {
                  people_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          user_id: user_id,
        },
      });
      return result.data.campaigns;
    },
    setApprovedCarrier: async function (user_id, carrier_id, approved) {
      // if approved, then insert, else delete
      if (approved) {
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation InsertAllowedCarrier($user_id: Int!, $carrier_id: Int!) {
              insert_allowed_carriers_one(
                object: { user_id: $user_id, carrier_id: $carrier_id }
              ) {
                created_at
              }
            }
          `,
          variables: {
            user_id,
            carrier_id,
          },
        });
        return data.insert_allowed_carriers_one.created_at;
      } else {
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation DeletedAllowedCarrier($user_id: Int!, $carrier_id: Int!) {
              delete_allowed_carriers_by_pk(
                carrier_id: $carrier_id
                user_id: $user_id
              ) {
                created_at
              }
            }
          `,
          variables: {
            user_id,
            carrier_id,
          },
        });
        return data.delete_allowed_carriers_by_pk.created_at;
      }
    },
    getAllCarriers: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query AllCarriers {
            carriers {
              id
              name
            }
          }
        `,
      });
      return data.carriers;
    },
    getApprovedUserCarriers: async function (user_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query AllowedCarriers($user_id: Int!) {
            allowed_carriers(where: { user_id: { _eq: $user_id } }) {
              carrier_id
            }
          }
        `,
        variables: {
          user_id,
        },
      });
      return data.allowed_carriers;
    },

    campaignListAdmin: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query campaignListAdmin {
            campaigns(where: { status: { _neq: "created" } }) {
              id
              city
              state
              requested_count
              status
              venue_name
              user {
                id
                first_name
                last_name
              }
              events {
                start_date
                start_time
                capacity
                households {
                  people_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        `,
      });
      return data.campaigns;
    },
    campaignById: async function (id) {
      let result = await this.$apollo.query({
        query: gql`
          query CampaignById($id: Int!) {
            campaigns_by_pk(id: $id) {
              id
              requested_count
              city
              mail_date
              state
              status
              venue_name
              book_tracking_num
              street
              created
              zip
              osi_toon_fedex
              osi_toon_ready
              osi_toon_status
              automation
              user {
                id
                first_name
                last_name
                phone
                email
                business_street
                business_state
                business_city
                business_zip
              }
              events {
                capacity
                start_date
                start_time
                households {
                  id
                  people_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
                id
              }
              households {
                email
                status
                user_id
                people {
                  name
                  primary
                  id
                }
                event_id
                event {
                  id
                  start_date
                }
                campaign_id
                phone
                street
                city
                state
                zip
                sticky_notes
                lead_type
                created
                email1_sent
                email2_sent
                email3_sent
                email4_sent
                email5_sent

                id
              }
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return result.data.campaigns_by_pk;
    },
    updateCampaignToonReady: async function (id, ready) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateCampaignToonReady($id: Int!, $ready: Boolean!) {
            update_campaigns_by_pk(
              pk_columns: { id: $id }
              _set: { osi_toon_ready: $ready }
            ) {
              id
            }
          }
        `,
        variables: {
          id,
          ready,
        },
      });
      return data.update_campaigns_by_pk.id;
    },
    updateEventCapacity: async function (id, capacity) {
      let result = await this.$apollo.mutate({
        mutation: gql`
          mutation updateEventCapacity($id: Int!, $capacity: Int!) {
            update_events(
              where: { id: { _eq: $id } }
              _set: { capacity: $capacity }
            ) {
              affected_rows
            }
          }
        `,
        variables: {
          id: id,
          capacity: capacity,
        },
      });
      return result.data.update_events;
    },
    insert_person: async function (household_id, person) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation InsertPerson(
            $household_id: Int!
            $name: String!
            $primary: Boolean!
          ) {
            insert_people_one(
              object: {
                household_id: $household_id
                name: $name
                primary: $primary
              }
            ) {
              id
              name
              primary
            }
          }
        `,
        variables: {
          household_id,
          name: person.name,
          primary: person.primary || false,
        },
      });
      return data.insert_people_one;
    },
    delete_person_by_pk: async function (person_id) {
      let result = await this.$apollo.mutate({
        mutation: gql`
          mutation DeletePerson($person_id: Int!) {
            delete_people_by_pk(id: $person_id) {
              id
            }
          }
        `,
        variables: {
          person_id: person_id,
        },
      });
      return result.data.delete_people_by_pk.id;
    },
    update_person_by_pk: async function (person_id, person) {
      const _person = { ...person };
      delete _person.id;
      delete _person.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdatePerson($id: Int!, $_set: people_set_input) {
            update_people_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: person_id,
          _set: _person,
        },
      });
      return data.update_people_by_pk;
    },
    insert_client: async function (client) {
      const _client = { ...client };
      delete _client.id;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_clients_one($client: clients_insert_input!) {
            insert_clients_one(object: $client) {
              id
            }
          }
        `,
        variables: {
          client: _client,
        },
      });
      return data.insert_clients_one.id;
    },

    insert_carrier_illy: async function (carrier_illy) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_carrier_illy_one(
            $object: carrier_illy_insert_input!
          ) {
            insert_carrier_illy_one(object: $object) {
              id
            }
          }
        `,
        variables: {
          object: carrier_illy,
        },
      });
      return data.insert_carrier_illy_one.id;
    },
    update_carrier_illy: async function (id, carrier_illy) {
      const _carrier_illy = { ...carrier_illy };
      delete _carrier_illy.id;
      delete _carrier_illy.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation update_carrier_illy_by_pk(
            $_set: carrier_illy_set_input!
            $id: Int!
          ) {
            update_carrier_illy_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id,
          _set: _carrier_illy,
        },
      });
      return data.update_carrier_illy_by_pk.id;
    },
    read_carrier_illy_by_client: async function (client_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query carrier_illy($client_id: Int!) {
            carrier_illy(where: { client_id: { _eq: $client_id } }) {
              client_id
              created_at
              id
              name
              updated_at
            }
          }
        `,
        variables: {
          client_id,
        },
      });
      return data.carrier_illy;
    },

    aws_presigned_get: async function (foldername, current_annuity_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query action_aws_presigned_get(
            $foldername: String!
            $current_annuity_id: Int!
          ) {
            action_aws_presigned_get(
              foldername: $foldername
              current_annuity_id: $current_annuity_id
            ) {
              ok
              result
            }
          }
        `,
        variables: {
          foldername,
          current_annuity_id,
        },
      });
      return data.action_aws_presigned_get;
    },

    transfer_group_reload: async function (id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query TransferGroupByPk($id: Int!) {
            transfer_group_by_pk(id: $id) {
              id
              current_annuity_ids
              transfer_annuities {
                id
                transfer_group_id
                bonus_percentage
                start_age
                withdrawal_amount
                withdrawal_type
                carrier_name
                end_age
                fee
                inflation_percentage
                interest_rate
                product_name
                qualified
              }
            }
          }
        `,
        variables: {
          id,
        },
      });
      return data.transfer_group_by_pk;
    },

    update_transfer_group: async function (id, tg) {
      const _tg = { ...tg };
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation update_transfer_group(
            $id: Int!
            $_set: transfer_group_set_input!
          ) {
            update_transfer_group_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: id,
          _set: _tg,
        },
      });
      return data.update_transfer_group_by_pk.id;
    },

    insert_transfer_group: async function (tg) {
      const _tg = { ...tg };
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_transfer_group_one(
            $tg: transfer_group_insert_input!
          ) {
            insert_transfer_group_one(object: $tg) {
              id
            }
          }
        `,
        variables: {
          tg: _tg,
        },
      });
      return data.insert_transfer_group_one.id;
    },

    insert_transfer_annuity: async function (ta) {
      const _ta = { ...ta };
      delete _ta.__typename;
      delete _ta.valid;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_transfer_annuity_one(
            $ta: transfer_annuity_insert_input!
          ) {
            insert_transfer_annuity_one(object: $ta) {
              id
            }
          }
        `,
        variables: {
          ta: _ta,
        },
      });
      return data.insert_transfer_annuity_one.id;
    },
    delete_transfer_annuity_by_pk: async function (id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteTransferAnnuity($id: Int!) {
            delete_transfer_annuity_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return data.delete_transfer_annuity_by_pk.id;
    },

    transfer_report: async function (id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query transfer_group_by_pk($id: Int!) {
            transfer_group_by_pk(id: $id) {
              client {
                age
                gender
                id
                life_exp
                name
                state
                user {
                  first_name
                  id
                  last_name
                }
              }
              current_annuity_ids
              transfer_annuities {
                bonus_percentage
                carrier_name
                end_age
                fee
                id
                inflation_percentage
                interest_rate
                product_name
                qualified
                start_age
                withdrawal_amount
                withdrawal_type
              }
              id
              result
            }
          }
        `,
        variables: {
          id: id,
        },
      });

      data.transfer_group_by_pk.current_annuity = [];

      for (
        let index = 0;
        index < data.transfer_group_by_pk.current_annuity_ids.length;
        index++
      ) {
        const id = data.transfer_group_by_pk.current_annuity_ids[index];
        let d = await this.current_annuity_report(id);
        data.transfer_group_by_pk.current_annuity.push(d);
      }

      return data.transfer_group_by_pk;
    },

    insert_calculator: async function (calculator) {
      const _calculator = { ...calculator };
      delete _calculator.id;
      delete _calculator.__typename;
      console.log(_calculator);
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_calculator_one(
            $calculator: calculator_insert_input!
          ) {
            insert_calculator_one(object: $calculator) {
              id
            }
          }
        `,
        variables: {
          calculator: _calculator,
        },
      });
      return data.insert_calculator_one.id;
    },
    update_calculator: async function (id, calculator) {
      const _calculator = { ...calculator };
      delete _calculator.id;
      delete _calculator.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation update_calculator_by_pk(
            $id: Int!
            $_set: calculator_set_input!
          ) {
            update_calculator_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: id,
          _set: _calculator,
        },
      });
      return data.update_calculator_by_pk.id;
    },
    get_calculator: async function (calculator_id) {
      const { data } = await this.$apollo.query({
        fetchPolicy: "network-only",
        query: gql`
          query calculator($id: Int!) {
            calculator_by_pk(id: $id) {
              bonus
              calculator_type
              created_at
              deposit
              end_age
              fees
              fees2
              id
              inflation
              qualified
              rate
              rate2
              result
              start_age
              updated_at
              wd_amount
              description
            }
          }
        `,
        variables: {
          id: calculator_id,
        },
      });
      return data.calculator_by_pk;
    },

    current_annuity_report: async function (current_annuity_id, force) {
      const { data } = await this.$apollo.query({
        fetchPolicy: force ? "network-only" : "cache-first",
        query: gql`
          query current_annuity_by_pk($id: Int!) {
            current_annuity_by_pk(id: $id) {
              id
              result
              has_pdf
              current_value
              original_value
              carrier_name
              product_name
              description
              asset_type
              mne
              admin
              expense_ratio
              additional_fees
              total_wds
              issue_date
              statement_date
              is_income_rider
              free_wd_percent
              first_year_free_wd_percent
              free_wd_type
              qualified
              total_surrender_dollar
              total_withdrawals_since_statement
              additional_wds_prior_transfer
              income_rider_amount
              income_account_value
              total_wds
              surrender_years
              surrender_values
              income_start_age
              valid
            }
          }
        `,
        variables: {
          id: current_annuity_id,
        },
      });
      console.log("returning report data", data.current_annuity_by_pk);
      return data.current_annuity_by_pk;
    },

    insert_current_annuity: async function (current_annuity) {
      const _current_annuity = { ...current_annuity };
      delete _current_annuity.id;
      delete _current_annuity.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation insert_current_annuity_one(
            $current_annuity: current_annuity_insert_input!
          ) {
            insert_current_annuity_one(object: $current_annuity) {
              id
            }
          }
        `,
        variables: {
          current_annuity: _current_annuity,
        },
      });
      return data.insert_current_annuity_one.id;
    },
    update_current_annuity: async function (id, current_annuity) {
      const _current_annuity = { ...current_annuity };
      delete _current_annuity.id;
      delete _current_annuity.__typename;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation update_current_annuity_by_pk(
            $id: Int!
            $_set: current_annuity_set_input!
          ) {
            update_current_annuity_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: id,
          _set: _current_annuity,
        },
      });
      return data.update_current_annuity_by_pk.id;
    },
    client_by_user_id: async function (user_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query UserClients($user_id: Int!) {
            clients(where: { user_id: { _eq: $user_id } }) {
              id
              name
              gender
              state
              age
              life_exp
              user_id
              created_at
              user {
                id
                first_name
                last_name
              }
              client_current_annuities {
                id
                qualified
                valid
                result
                created_at
                carrier_name
                product_name
                issue_date
                statement_date
                asset_type
                current_value
                has_pdf
              }
              calculators {
                id
              }
            }
          }
        `,
        variables: {
          user_id,
        },
      });
      return data.clients;
    },
    admin_client: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query UserClients {
            clients {
              id
              name
              gender
              state
              age
              life_exp
              user_id
              user {
                id
                first_name
                last_name
              }
              client_current_annuities {
                id
                qualified
                valid
                result
                created_at
                carrier_name
                product_name
                issue_date
                statement_date
                asset_type
                current_value
                has_pdf
              }
              calculators {
                id
              }
            }
          }
        `,
      });
      return data.clients;
    },
    client_by_pk: async function (client_id, fetchPolicy) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ReadClient($client_id: Int!) {
            clients_by_pk(id: $client_id) {
              id
              name
              gender
              state
              age
              life_exp
              user_id
              client_current_annuities {
                id
                valid
                result
                created_at
                carrier_name
                product_name
                issue_date
                statement_date
                asset_type
                current_value
                qualified
                has_pdf
                created_at
              }
              transfer_groups {
                id
                current_annuity_ids
                transfer_annuities {
                  carrier_name
                }
                result
                created_at
              }
              calculators {
                id
                calculator_type
                created_at
                description
              }
            }
          }
        `,
        variables: {
          client_id,
        },
        fetchPolicy: fetchPolicy || "cache-first",
      });
      return data.clients_by_pk;
    },
    simple_client_by_pk: async function (client_id) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ReadClient($client_id: Int!) {
            clients_by_pk(id: $client_id) {
              id
              name
              gender
              state
              age
              life_exp
              user_id
              user {
                id
                first_name
                last_name
                email
                phone
              }
            }
          }
        `,
        variables: {
          client_id,
        },
      });
      return data.clients_by_pk;
    },
    update_clients_by_pk: async function (client_id, client) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateClient($id: Int!, $_set: clients_set_input) {
            update_clients_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: client_id,
          _set: {
            name: client.name,
            gender: client.gender,
            state: client.state,
            age: client.age,
            life_exp: client.life_exp,
          },
        },
      });
      return data.update_clients_by_pk;
    },
    delete_client_by_pk: async function (id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteClient($id: Int!) {
            delete_clients_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return data.delete_clients_by_pk;
    },
    delete_household_by_pk: async function (id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteUser($id: Int!) {
            delete_households_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });
      return data.delete_households_by_pk;
    },
    insert_household: async function (household) {
      const _household = { ...household };
      delete _household.id;
      delete _household.people;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateHousehold($household: households_insert_input!) {
            insert_households_one(object: $household) {
              id
            }
          }
        `,
        variables: {
          household: _household,
        },
      });
      return data.insert_households_one.id;
    },
    update_household_by_pk: async function (household_id, household) {
      const _household = { ...household };
      delete _household.id;
      delete _household.__typename;
      delete _household.people;
      delete _household.event;
      delete _household.created_at;
      delete _household.updated_at;
      delete _household.sticky_notes;
      delete _household.spousename; // fake field used for sorting
      delete _household.clientname; // fake field used for sorting
      delete _household.event_start_date; // fake field used for sorting
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateHousehold($id: Int!, $_set: households_set_input) {
            update_households_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: household_id,
          _set: _household,
        },
      });
      return data.update_households_by_pk;
    },
    updateHouseholdNotes: async function (id, notes) {
      let result = await this.$apollo.mutate({
        mutation: gql`
          mutation updateHouseholdNotes($id: Int!, $notes: _varchar!) {
            update_households(
              where: { id: { _eq: $id } }
              _set: { sticky_notes: $notes }
            ) {
              affected_rows
            }
          }
        `,
        variables: {
          id: id,
          notes: `{${notes.join(",")}}`,
        },
      });
      return result.data.update_events;
    },
    sendTemplateEmail: async function (
      recipient_email,
      recipient_name,
      template_id,
      variables
    ) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation SendTemplateEmail(
            $data: String!
            $recipient_email: String!
            $recipient_name: String!
            $template_id: String!
          ) {
            action_send_template_email(
              data: $data
              recipient_email: $recipient_email
              subject: ""
              template_id: $template_id
              recipient_name: $recipient_name
            ) {
              ok
            }
          }
        `,
        variables: {
          recipient_email,
          recipient_name,
          template_id,
          data: JSON.stringify(variables),
        },
      });
      return data.action_send_template_email.ok;
    },
    sendEmail: async function (recipient_email, recipient_name, subject, html) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation SendEmail(
            $html: String!
            $recipient_email: String!
            $recipient_name: String!
            $subject: String!
          ) {
            action_send_email(
              html: $html
              recipient_email: $recipient_email
              recipient_name: $recipient_name
              subject: $subject
            ) {
              ok
            }
          }
        `,
        variables: {
          html,
          recipient_email,
          recipient_name,
          subject,
        },
      });
      return data.action_send_email.ok;
    },
    registerUser: async function (
      email,
      first_name,
      last_name,
      password,
      phone,
      annuity_premium,
      current_imos,
      street,
      city,
      state,
      zip
    ) {
      const mutation = gql`
        mutation VueRegistration(
          $email: String!
          $first_name: String!
          $last_name: String!
          $password: String!
          $phone: String!
          $annuity_premium: String!
          $current_imos: String!
          $street: String!
          $city: String!
          $state: String!
          $zip: String!
        ) {
          action_registration(
            annuity_premium: $annuity_premium
            business_name: ""
            city: $city
            current_imos: $current_imos
            email: $email
            first_name: $first_name
            phone: $phone
            state: $state
            street: $street
            zip: $zip
            last_name: $last_name
            password: $password
          ) {
            ok
            id
            reason
          }
        }
      `;
      const { data } = await this.$apollo.mutate({
        mutation,
        variables: {
          email,
          first_name,
          last_name,
          password,
          phone,
          annuity_premium,
          current_imos,
          street,
          city,
          state,
          zip,
        },
      });
      return data.action_registration;
    },
    login: async function (email, password) {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query Login($email: String!, $password: String!) {
              action_login(email: $email, password: $password) {
                jwt
                ok
                reason
              }
            }
          `,
          variables: {
            email: email,
            password: password,
          },
        });
        return data.action_login;
      } catch (e) {
        return {
          ok: false,
          reason: "Unknown Server Error.",
        };
      }
    },
    getLE: async function (gender, age) {
      let result = await this.$apollo.query({
        query: gql`
          query action_get_life_exp($gender: String!, $age: Int!) {
            action_get_life_exp(age: $age, gender: $gender) {
              ok
              life_exp
            }
          }
        `,
        variables: {
          gender: gender,
          age: age,
        },
      });
      return result.data.action_get_life_exp;
    },
    get_marketers: async function () {
      const { data } = await this.$apollo.query({
        query: gql`
          query GetMarketers {
            users(where: { acl: { _gt: 7 }, id: { _nin: [1, 491] } }) {
              id
              first_name
              last_name
            }
          }
        `,
      });

      return data.users
        .map((user) => {
          return {
            id: user.id,
            text: `${user.first_name} ${user.last_name}`,
            first_name: user.first_name,
            last_name: user.last_name,
          };
        })
        .sort((a, b) => {
          return a.text.localeCompare(b.text);
        });
    },
    delete_user_by_pk: async function (id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteUser($id: Int!) {
            delete_users_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });

      return data.action_delete_user;
    },
    update_user_sticky_by_pk: async function (user_id, sticky) {
      let _user = {
        sticky_notes: `{${sticky.join(",")}}`,
      };
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUserStickyNotes($id: Int!, $_set: users_set_input) {
            update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: user_id,
          _set: _user,
        },
      });
      return data.update_users_by_pk;
    },
    update_user_by_pk: async function (user_id, user) {
      const _user = { ...user };
      delete _user.id;
      delete _user.__typename;
      delete _user.allowed_carriers;
      delete _user.acl;
      delete _user.created_at;
      delete _user.updated_at;
      delete _user.sticky_notes;
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation UpdateUser($id: Int!, $_set: users_set_input) {
            update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
              id
            }
          }
        `,
        variables: {
          id: user_id,
          _set: _user,
        },
      });
      return data.update_users_by_pk;
    },
    get_user_by_pk: async function (uid) {
      const { data } = await this.$apollo.query({
        query: gql`
          query AdminUser($user_id: Int!) {
            users_by_pk(id: $user_id) {
              acl
              active
              all_in
              annuity_premium
              approved
              approved_date
              bootcamp_status
              business_name
              city
              current_imos
              email
              first_name
              hidden
              id
              last_name
              allowed_carriers {
                carrier_id
              }
              marketer_id
              phone
              state
              status
              sticky_notes
              street
              zip
              inflationalpha_flag
              software_expiry
              has_ppt
              seminar_control_training
              production_level
              mail_credit
              software_expiry
              source
            }
          }
        `,
        variables: {
          user_id: uid,
        },
      });

      return data.users_by_pk;
    },
    get_bonus_fee: async function (product_id, state, age) {
      const { data } = await this.$apollo.query({
        query: gql`
          query ZLTBonus($product_id: Int!, $state: jsonb, $age: Int) {
            products_by_pk(id: $product_id) {
              state_availabilities(where: { states: { _contains: $state } }) {
                fee
                bonuses(
                  where: { max_age: { _gte: $age }, min_age: { _lte: $age } }
                ) {
                  bonus_amount
                }
              }
            }
          }
        `,
        variables: {
          product_id,
          state,
          age,
        },
      });
      let bonus = data.products_by_pk?.state_availabilities[0]?.bonuses[0] || 0;
      return {
        bonus: bonus ? bonus.bonus_amount : 0,
        fee: data.products_by_pk?.state_availabilities[0]?.fee || 0,
      };
    },
    get_products_by_cid: async function (cid, state, age) {
      let response = await this.$apollo.query({
        query: gql`
          query AllProducts($carrier_id: Int!, $state: jsonb = "", $age: Int) {
            products(where: { carrier_id: { _eq: $carrier_id } }) {
              id
              name
              state_availabilities(
                where: {
                  states: { _contains: $state }
                  min_age: { _lte: $age }
                  max_age: { _gte: $age }
                }
              ) {
                fee
                bonuses(
                  where: { min_age: { _lte: $age }, max_age: { _gte: $age } }
                ) {
                  bonus_amount
                }
              }
            }
          }
        `,
        variables: {
          carrier_id: cid,
          state,
          age,
        },
      });

      let products = [];
      response.data.products.forEach((product) => {
        if (product.state_availabilities.length > 0) {
          products.push({
            id: product.id,
            name: product.name,
            bonus:
              product.state_availabilities[0].bonuses[0]?.bonus_amount || 0,
            fee: product.state_availabilities[0].fee,
          });
        }
      });

      products.sort(function (a, b) {
        return a.name < b.name ? -1 : 1;
      });

      return products;
    },
    delete_generic_by_pk: async function (table_name, id) {
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation DeleteGeneric($id: Int!) {
            delete_${table_name}_by_pk(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: id,
        },
      });

      return data;
    },
    get_by_pk: async function (table_name, pk, columns) {
      const response = await this.$apollo.query({
        query: gql`query MyQuery($id: Int!) {
          ${table_name}_by_pk(id: $id) {
            ${columns}
          }
        }`,
        variables: {
          id: pk,
        },
      });
      return response.data[table_name + "_by_pk"];
    },
    // get_by_all: async function(table_name, columns) {
    //   const response = await this.$apollo.query({
    //     query: gql`query get_by_all_${table_name} {
    //       ${table_name} {
    //         ${columns}
    //       }
    //     }`,
    //   });
    //   return response.data[table_name];
    // },
  },
};
